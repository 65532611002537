import * as Diff from 'diff'
import { Component, createSignal } from 'solid-js'
import styles from '~/components/debug-textdiff/debug-textdiff.module.scss'

const DebugTextdiff: Component = () => {
  const [original, setOriginal] = createSignal('Lorem ipsum dolor sit amet consectetur adipisicing elit.')
  const [correction, setCorrection] = createSignal('Lorem ipsum dolores sit amet consectetur adipisicing elit.')

  const textDiff = () => {
    const diff = Diff.diffWords(original(), correction())
    const result: Node[] = []
    diff.forEach(entry => {
      if (!entry.removed) {
        const span = document.createElement('span')
        span.appendChild(document.createTextNode(entry.value))
        if (entry.added) {
          span.style.color = 'red'
        }
        result.push(span)
      }
    })
    return result
  }

  return (
    <div class={styles.container}>
      <div class={styles.fields}>
        <label>Original</label>
        <textarea onInput={e => setOriginal(e.target.value)}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
        </textarea>
        <label>Correction</label>
        <textarea onInput={e => setCorrection(e.target.value)}>
          Lorem ipsum dolores sit amet consectetur adipisicing elit.
        </textarea>
      </div>
      <aside>
        <div class={styles.textDiff}>{textDiff()}</div>
        <button class={styles.button}>Compute Diff</button>
      </aside>
    </div>
  )
}

export default DebugTextdiff
